/* src/ImageSlider.css */
.slider-container {
    width: 100%;
    margin: auto;
  }
  
  .slider-item {
    position: relative;
    padding-bottom: 100%; /* This keeps the aspect ratio 1:1 */
    overflow: hidden;
  }
  
  .slider-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .slick-dots li button:before {
    color: gray; /* Default dot color */
    font-size: 12px;
  }
  
  /* Highlight the active dot */
  .slick-dots li.slick-active button:before {
    color: #ea6852; /* Active dot color */
  }
  
  /* Optional: Adjust spacing or alignment */
 
 